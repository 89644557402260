import React, { useEffect } from "react";
import {
  EmptyState,
  CustomModalLayout,
  TextButton,
  Modal,
  Text,
  Card,
  Box,
  Page,
  Button,
  Layout,
  WixDesignSystemProvider,
  StatisticsWidget,
  Cell,
  Loader,
  Tag,
  CounterBadge,
  SectionHelper,
  Timeline,
  InputArea,
  CopyClipboard,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import { useTranslation } from "react-i18next";
import { Table } from "@wix/design-system";
import { SectionHeader } from "@wix/design-system";

const benefits: any = {
  lite: 30,
  plus: 150,
  pro: 350,
  elite: 1000,
};

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation();
  ReactTagManager.init(tagManagerArgs);

  const instance = new URLSearchParams(window.location.search).get("instance");

  const [domainInfo, setDomainInfo] = React.useState({} as any);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [settings, setSettings] = React.useState(null as any);

  const [keywords, setKeywords] = React.useState(null as any);

  const [showLinksModal, setShowLinksModal] = React.useState(false);
  const [currentCycle, setCurrentCycle] = React.useState({} as any);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/backlinks/_functions", // Replace with your API base URL
      applicationID: "4edd7dc0-6cba-45a0-afd9-2a6af961cb20", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getSettings();
    getDomainInfo();
    getKeywords();
    // setup auto refresh instance every 10 secs
    const interval = setInterval(() => {
      basePlatform.setInstance(setInstanceData, setIsUpgraded);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (!instance) {
    return null;
  }

  const BASE_URL = `https://certifiedcode.wixsite.com/backlinks/_functions`;

  function saveSettings() {
    fetch(BASE_URL + "/api/saveSettings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify([settings]),
    })
      .then((data) => {
        setSettings(data);
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    fetch(BASE_URL + "/api/getSettings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify([]),
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
      })
      .catch(() => {});
  }

  function getKeywords() {
    fetch(BASE_URL + "/api/getKeywords", {
      method: "POST",
      headers: {
        Authorization: instance!,
      },
      body: JSON.stringify([]),
    })
      .then((response) => response.json())
      .then((data) => {
        setKeywords(data);
      })
      .catch(() => {
        setKeywords({ success: false });
      });
  }

  function getDomainInfo() {
    fetch(BASE_URL + "/api/getDomainInfo", {
      method: "POST",
      headers: {
        Authorization: instance!,
      },
      body: JSON.stringify([]),
    })
      .then((response) => response.json())
      .then((data) => {
        setDomainInfo(data);
      })
      .catch(() => {
        setDomainInfo({ success: false });
      });
  }

  function convertToDate(dateString: string) {
    if (!dateString) {
      return new Date();
    }
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
  }

  function convertStringToFormat(input: string) {
    const result = [];
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    const entries = input.split("\n");

    let currentEntry = { date: "", links: "" };

    for (let line of entries) {
      if (dateRegex.test(line.trim())) {
        if (currentEntry.date) {
          result.push({ ...currentEntry });
        }
        currentEntry = { date: line.trim(), links: "" };
      } else {
        currentEntry.links += (currentEntry.links ? "\n" : "") + line.trim();
      }
    }

    if (currentEntry.date) {
      result.push({ ...currentEntry });
    }

    return result;
  }

  const planId = instanceData?.instance?.billing?.packageName;
  const expirationDate = new Date(
    instanceData?.instance?.billing?.expirationDate
  ).toLocaleDateString();

  const records = (currentCycle?.links || "")
    .split("\n")
    .map((link: string) => ({ link }));
  const columns = [
    {
      title: "URL",
      render: (row: any) => row.link,
    },
  ];

  const getNextCycleDate = () => {
    try {
      const nextDate = convertToDate(
        convertStringToFormat(settings?.backlinks)[0].date
      );
      nextDate.setDate(nextDate.getDate() + 45);
      return (
        `Expects the next backlinks to be delivered on ` +
        nextDate.toLocaleDateString()
      );
    } catch (error) {
      const dateBilled = new Date(instanceData?.instance?.billing?.timeStamp);
      dateBilled.setDate(dateBilled.getDate() + 21);
      return dateBilled.toLocaleDateString();
    }
  };

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Modal
        isOpen={showLinksModal}
        onRequestClose={() => setShowLinksModal(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          primaryButtonText={
            records.length - benefits[planId] > 0 &&
            `Upgrade to access ${
              records.length - benefits[planId]
            } more links instantly`
          }
          secondaryButtonText="Close"
          primaryButtonOnClick={() => {
            window.open(basePlatform.getUpgradeUrl(), "_blank");
          }}
          title={convertToDate(currentCycle.date).toLocaleDateString()}
          onHelpButtonClick={() => {
            window.Intercom("showNewMessage", "I need help with my backlinks");
          }}
          onCloseButtonClick={() => setShowLinksModal(false)}
          content={
            <Layout>
              <Cell span={12}>
                {records.length > benefits[planId] && (
                  <SectionHelper
                    // showPrefixIcon
                    // prefixIcon={<Icons.QuickAccess />}
                    appearance="premium"
                    title={`Instantly access ${
                      records.length - benefits[planId]
                    } more links when you upgrade to a higher plan. Your current plan only offers ${
                      benefits[planId]
                    } links per month.`}
                    actionText="Upgrade"
                    onAction={() => {
                      window.open(basePlatform.getUpgradeUrl(), "_blank");
                    }}
                  >
                    With more links, you can improve your SEO ranking faster.
                    Refresh this page after upgrading to see all your links.
                  </SectionHelper>
                )}
              </Cell>
              <Cell span={12}>
                <CopyClipboard
                  value={records
                    .map((r: any) => r.link)
                    .slice(0, benefits[planId])
                    .join("\n")}
                  resetTimeout={1500}
                >
                  {({ isCopied, copyToClipboard }) => (
                    <Layout>
                      <Cell span={10}>
                        <Table
                          skin="standard"
                          data={records
                            .slice(0, benefits[planId])}
                          columns={columns}
                          rowVerticalPadding="tiny"
                        >
                          <Table.Content />
                        </Table>
                      </Cell>
                      <Cell span={2}>
                        <TextButton
                          onClick={() => copyToClipboard()}
                          size="small"
                          prefixIcon={<Icons.DuplicateSmall />}
                        >
                          {!isCopied ? "Copy" : "Copied!"}
                        </TextButton>
                      </Cell>
                    </Layout>
                  )}
                </CopyClipboard>
              </Cell>
            </Layout>
          }
        />
      </Modal>
      <Page height="100vh">
        <Page.Header
          title={t("backlinks-title")}
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/4edd7dc0-6cba-45a0-afd9-2a6af961cb20"
                  target="_blank"
                >
                  {t("backlinks-review-button")}
                </Button>
              )}
              <Button
                skin={isUpgraded ? "premium-light" : "premium"}
                as="a"
                href={basePlatform.getUpgradeUrl()}
                target={"_blank"}
                prefixIcon={<Icons.PremiumFilled />}
              >
                {isUpgraded ? t("manage-plan-button") : t("rank-button")}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            {instanceData?.site && !instanceData?.site?.url && <Cell span={12}>
              <SectionHelper
                title="IMPORTANT: Your site needs to be published to add backlinks"
                appearance="danger"
              >
                You can do this in the Wix editor by clicking the "Publish" button
                in the top right corner of the page.
              </SectionHelper>
            </Cell>}
            <Cell>
              <Card>
                {domainInfo ? (
                  domainInfo?.success !== false ? (
                    <StatisticsWidget
                      items={[
                        {
                          value: domainInfo?.result?.domain_power,
                          description: t("domain-title"),
                          descriptionInfo: t("domain-info"),
                        },
                        {
                          value: domainInfo?.result?.organic_clicks,
                          description: t("organic-title"),
                          descriptionInfo: t("organic-info"),
                        },
                        {
                          value: domainInfo?.result?.average_rank,
                          description: t("avg-title"),
                          descriptionInfo: t("avg-info"),
                        },
                        {
                          value: domainInfo?.result?.keywords_rank,
                          description: t("keyword-title"),
                          descriptionInfo: t("keyword-info"),
                        },
                      ]}
                    />
                  ) : null
                ) : (
                  <Loader size={"small"} />
                )}
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header title={t("back-header-title")} />
                <Card.Content>
                  {!settings && <Loader size={"small"} />}
                  {settings && !isUpgraded && (
                    <EmptyState
                      theme="section"
                      title={t("back-title")}
                      subtitle={t("back-subtitle")}
                    >
                      {
                        <TextButton
                          as="a"
                          target="_blank"
                          href={basePlatform.getUpgradeUrl()}
                          prefixIcon={<Icons.Google />}
                        >
                          {t("back-rank-text")}
                        </TextButton>
                      }
                    </EmptyState>
                  )}
                  {settings && isUpgraded && settings?.backlinks && (
                    <Box gap="SP2" direction="vertical">
                      <SectionHelper>
                        {
                          // take the first date from the backlinks and add 45 days
                          getNextCycleDate()
                        }
                      </SectionHelper>
                      <Timeline
                        items={convertStringToFormat(settings?.backlinks).map(
                          (v: any) => ({
                            label: convertToDate(v.date).toLocaleDateString(),
                            customPrefix: <Icons.StatusCompleteFilledSmall />,
                            labelAction: (
                              <TextButton
                                size="small"
                                onClick={() => {
                                  setCurrentCycle(v);
                                  setShowLinksModal(true);
                                }}
                              >
                                View Links
                              </TextButton>
                            ),
                          })
                        )}
                      />
                      {/* <CopyClipboard
                        value={settings?.backlinks}
                        resetTimeout={1500}
                      >
                        {({ isCopied, copyToClipboard }: { isCopied: boolean; copyToClipboard: () => void }) => (
                          <Layout>
                            <Cell span={10}>
                              <InputArea
                                dataHook="backlinks"
                                readOnly
                                value={settings?.backlinks}
                              />
                            </Cell>
                            <Cell span={2}>
                              <TextButton
                                onClick={() => copyToClipboard()}
                                size="small"
                                prefixIcon={<Icons.DuplicateSmall />}
                              >
                                {!isCopied ? "Copy" : "Copied!"}
                              </TextButton>
                            </Cell>
                          </Layout>
                        )}
                      </CopyClipboard> */}
                    </Box>
                  )}
                  {settings && isUpgraded && !settings?.backlinks && (
                    <Box gap="SP2" direction="vertical">
                      <EmptyState
                        theme="section"
                        title={t("work-title")}
                        subtitle={`Expects the next backlinks to be delivered on ${getNextCycleDate()} or sooner`}
                      ></EmptyState>

                      <SectionHelper
                        title={"Why does it takes so long?"}
                        actionText={"Contact Us"}
                        onAction={() => {
                          window.Intercom(
                            "showNewMessage",
                            "I need help with my backlinks"
                          );
                        }}
                        // appearance=
                      >
                        {
                          "We use quality backlinks to improve your SEO ranking. This process takes time to ensure the best results."
                        }
                      </SectionHelper>
                    </Box>
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={9}>
              <Card>
                <Card.Content>
                  <Box direction="vertical" gap="24px">
                    {settings?.keywords && (
                      <Box align="space-between">
                        <Text weight="bold">Keywords we are using</Text>
                        <Text skin="disabled">
                          You've requested updates to these keywords.
                        </Text>
                        {/* <TextButton onClick={() => {}}>Add</TextButton> */}
                      </Box>
                    )}
                    {settings?.keywords && (
                      <InputArea
                        readOnly
                        value={settings?.keywords}
                      ></InputArea>
                    )}
                    {keywords ? (
                      keywords?.success !== false ? (
                        <Box>
                          <Box align="space-between">
                            <Text weight="bold">
                              {t("suggested-title")}{" "}
                              {keywords && typeof keywords === "object" ? (
                                <CounterBadge>
                                  {Object.keys(keywords || {})?.length}{" "}
                                </CounterBadge>
                              ) : (
                                <Loader size={"small"} />
                              )}
                            </Text>
                            <Text skin="disabled">{t("suggested-text")}</Text>
                            {/* <TextButton onClick={() => {}}>Add</TextButton> */}
                          </Box>
                          <Box gap="6px" flexWrap="wrap">
                            {Object.keys(keywords || {})
                              .filter((v) => !!keywords[v].text)
                              .map((v: any) => (
                                <Tag
                                  id={keywords[v].text}
                                  removable={false}
                                  theme={
                                    keywords[v].competition_level === "LOW"
                                      ? "success"
                                      : keywords[v].competition_level ===
                                        "MEDIUM"
                                      ? "warning"
                                      : keywords[v].competition_level === "HIGH"
                                      ? "error"
                                      : "lightOutlined"
                                  }
                                  thumb={
                                    <Box width={50}>
                                      <CounterBadge>
                                        {keywords[
                                          v
                                        ].competition_level.substring(0, 1)}
                                      </CounterBadge>
                                    </Box>
                                  }
                                  // onRemove={() => {
                                  //   setSettings({
                                  //     ...settings,
                                  //     keywords: keywords.filter(
                                  //       (x: any) => x !== v
                                  //     ),
                                  //   });
                                  //   saveSettings();
                                  // }}
                                >
                                  {keywords[v].text}
                                </Tag>
                              ))}
                          </Box>
                          <Box align="space-between">
                            <Text>{t("legend-text")}</Text>
                          </Box>
                          <Box gap="6px" flexWrap="wrap">
                            <Tag
                              id="LOW"
                              removable={false}
                              theme="success"
                              thumb={
                                <Box width={50}>
                                  <CounterBadge>{t("low-badge")}</CounterBadge>
                                </Box>
                              }
                            >
                              {t("low-text")}
                            </Tag>
                            <Tag
                              id="MEDIUM"
                              removable={false}
                              theme="warning"
                              thumb={
                                <Box width={50}>
                                  <CounterBadge>
                                    {t("medium-badge")}
                                  </CounterBadge>
                                </Box>
                              }
                            >
                              {t("medium-text")}
                            </Tag>
                            <Tag
                              id="HIGH"
                              removable={false}
                              theme="error"
                              thumb={
                                <Box width={50}>
                                  <CounterBadge>{t("high-badge")}</CounterBadge>
                                </Box>
                              }
                            >
                              {t("high-text")}
                            </Tag>
                            <Tag
                              id="UNKNOWN"
                              removable={false}
                              theme="lightOutlined"
                              thumb={
                                <Box width={50}>
                                  <CounterBadge>
                                    {t("unknown-badge")}
                                  </CounterBadge>
                                </Box>
                              }
                            >
                              {t("unknown-text")}
                            </Tag>
                          </Box>
                        </Box>
                      ) : null
                    ) : (
                      <Loader size={"small"} />
                    )}
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={3}>
              <Card>
                <Card.Header title={t("plan-title")} />
                <Card.Divider />
                <Card.Content>
                  <Box gap="SP2" direction="vertical">
                    {instanceData?.instance?.billing?.autoRenewing ===
                      false && (
                      <SectionHelper
                        title={t("backlinks-suspended-message", {
                          expirationDate,
                        })}
                        actionText={t("helper-action")}
                        onAction={() => {
                          window.open(
                            `https://premium.wix.com/wix/api/mpContainerStaticController?originWidgetName=billings`,
                            "_blank"
                          );
                        }}
                        appearance="danger"
                      >
                        {t("helper-text")}
                      </SectionHelper>
                    )}
                    <Timeline
                      items={[
                        {
                          label: t("label-month"),
                          customPrefix: planId ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: !planId && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              {t("unlock-button")}
                            </Button>
                          ),
                        },
                        {
                          label: t("label-month-150"),
                          customPrefix: ["plus", "pro", "elite"].includes(
                            planId
                          ) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: planId === "lite" && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              {t("unlock-button")}
                            </Button>
                          ),
                        },
                        {
                          label: t("label-month-350"),
                          customPrefix: ["pro", "elite"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: planId === "plus" && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              {t("unlock-button")}
                            </Button>
                          ),
                        },
                        {
                          label: t("label-month-1000"),
                          customPrefix: ["elite"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: planId === "pro" && (
                            <Button
                              skin="dark"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              {t("go-elite-button")}
                            </Button>
                          ),
                        },
                        {
                          label: t("seo-weekly"),
                          customPrefix: ["elite"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                        },
                        {
                          label: t("success-manager"),
                          customPrefix: ["elite"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                        },
                      ]}
                    />
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
